import React from 'react';
import { Modal, Button } from 'react-bootstrap';

type WarningModalProps = {
    show: boolean;
    onShare: () => void;
    onClose: () => void;
    children: React.ReactNode;
};

export default function WarningModalComponent({ show, onShare, onClose, children }: WarningModalProps) {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>CANCELAR</Button>
                <Button onClick={onShare}>SIM</Button>
            </Modal.Footer>
        </Modal>
    );
}
