import * as material from 'material-colors'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// From https://casesandberg.github.io/react-color/
import clsx from 'clsx'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'
import {
  ArrowLeft,
  CloudArrowUp,
  Download,
  Eye,
  FileEarmarkPlus,
  FolderPlus,
  Pencil,
  Trash,
  Upload,
} from 'react-bootstrap-icons'
import { CirclePicker } from 'react-color'
import {
  Collection,
  CollectionImport,
  CollectionNotepadsItem,
  Notepad,
} from '../../api/model'
import {
  useCollectionCreate,
  useCollectionUpdate,
  useCollectionsList,
  useDeleteCollection,
  useDeleteNotepad,
  useNotepadCollectionImport,
  useNotepadCollectionTemplateImport,
  useNotepadCreate,
  useNotepadUpdate,
} from '../../api/notepad/notepad'
import { Loading, Typography } from '../../atoms'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import { NotepadCollectionTitleDisplay } from '../../molecules'
import ColorPicker from '../../molecules/ColorPicker/ColorPicker'
import NotepadBoard from '../../molecules/NotepadBoard/NotepadBoard'
import {
  exportNotepadCollection,
  importNotepadCollection,
} from '../../utils/notepadExportImport'
import './NotepadCollectionsBoard.css'
import classes from './NotepadCollectionsBoard.module.css'

/**
 * TODO:
 *
 * - Move notepads from one collection to another on edit
 *
 *
 * - Edit tutorial
 * - Allow user to filter collections
 *  - By name
 *  - By color
 * - Allow user to sort collections
 *  - By name
 *  - By date created
 *
 * - Replace the default notepad view with the collection view when user highlights text on exam page
 *
 * - Replace the default collections with the user's collections (run script)
 */

type CollectionFormValues = {
  notepadCollectionName: string
}

type NotepadFormValues = {
  notepadCollectionIdx: number
  notepadName: string
}

interface NotepadCollectionsBoardProps {
  addMode?: boolean
  highlightedText?: string
  initialEditMode?: boolean
  selectingMode?: boolean
  selectedNotepadCollectionsIds?: string[]
  userIsAdmin?: boolean
  setIsLoading?: (isLoading: boolean) => void
  setSelectedNotepadCollectionsIds?: (
    selectedNotepadCollectionsIds: string[],
  ) => void
}

const NotepadCollectionsBoard: FC<NotepadCollectionsBoardProps> = ({
  addMode = true,
  initialEditMode = false,
  selectingMode = false,
  selectedNotepadCollectionsIds = [],
  userIsAdmin = false,
  setIsLoading = () => {},
  setSelectedNotepadCollectionsIds = () => {},
}) => {
  const { t } = useTranslation()

  const fileImportCollectionInputRef = useRef<HTMLInputElement>(null)
  const fileImportTemplateCollectionInputRef = useRef<HTMLInputElement>(null)

  const defaultNotepadCollectionName = t('notepad.defaultCollectionName')
  const defaultNotepadCollectionColor = material.lightBlue['500'].toString()

  const defaultNotepadName = t('notepad.defaultName')

  const initialNotepadCollectionIdx = 0

  const [showEditNotepadCollectionModal, setShowEditNotepadCollectionModal] =
    useState(false)
  const [showEditNotepadModal, setShowEditNotepadModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // If true, the user can view and edit the selected notepad topics
  const [showNotepadBoard, setShowNotepadBoard] = useState(false)

  const [showDeleteNotepadModal, setShowDeleteNotepadModal] =
    useState<boolean>(false)
  const [notepadCollections, setNotepadCollections] = useState<Collection[]>([])

  const [notepadCollectionIdxSelected, setNotepadCollectionIdxSelected] =
    useState<number | undefined>(initialNotepadCollectionIdx)
  const [notepadIdSelected, setNotepadIdSelected] = useState<
    string | undefined
  >(undefined)

  const [notepadCollectionName, setNotepadCollectionName] = useState<
    string | undefined
  >(undefined)
  const [notepadCollectionColor, setNotepadCollectionColor] = useState<
    string | undefined
  >(defaultNotepadCollectionColor)

  const [newNotepadCollectionIdx, setNewNotepadCollectionIdx] = useState<
    number | undefined
  >(undefined)

  const [duplicatedNotepadCollectionName, setDuplicatedNotepadCollectionName] =
    useState<boolean>(false)

  const [notepadName, setNotepadName] = useState<string | undefined>(undefined)

  const [duplicatedNotepadName, setDuplicatedNotepadName] =
    useState<boolean>(false)

  const [checkedTags, setCheckedTags] = useState<string[]>([])

  const [showColorPicker, setShowColorPicker] = useState(false)

  // If true, the user can edit the current notepad's attributes
  const [editMode, setEditMode] = useState(initialEditMode)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectFavorites, setSelectFavorites] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)

  const { handleSubmit } = useForm()

  const onEditNotepadCollectionClicked = (index?: number) => {
    setNotepadCollectionIdxSelected(index)
    setShowEditNotepadCollectionModal(true)

    const currentNotepadCollection = notepadCollections[index!]

    setNotepadCollectionName(currentNotepadCollection.title)
    setNotepadCollectionColor(currentNotepadCollection.color)
  }

  const onDeleteNotepadCollectionClicked = (index: number) => {
    setShowDeleteModal(true)
    setNotepadCollectionIdxSelected(index)
  }

  const onDeleteNotepadClicked = (index: number, notepadId: string) => {
    setShowDeleteNotepadModal(true)
    setNotepadCollectionIdxSelected(index)
    setNotepadIdSelected(notepadId)
  }

  const createNewNotepadCollection = () => {
    setNotepadCollectionIdxSelected(undefined)
    setNotepadCollectionName(undefined)
    setNotepadCollectionColor(defaultNotepadCollectionColor)
    setShowEditNotepadCollectionModal(true)
  }

  const onCancelCreateNotepadCollection = () => {
    setShowColorPicker(false)
    setShowEditNotepadCollectionModal(false)
    setDuplicatedNotepadCollectionName(false)
    setNotepadCollectionName(undefined)
    setNotepadCollectionColor(defaultNotepadCollectionColor)

    if (notepadCollections.length > 0) {
      setNotepadCollectionIdxSelected(undefined)
    }
  }

  const onCancelCreateNotepad = () => {
    setShowColorPicker(false)
    setShowEditNotepadModal(false)
    setDuplicatedNotepadName(false)
    setNotepadCollectionIdxSelected(undefined)
    setNotepadIdSelected(undefined)
    setNewNotepadCollectionIdx(undefined)
  }

  const isNotepadSelected = (notepadId: string) => {
    return selectedNotepadCollectionsIds.includes(notepadId)
  }

  const onSelectAllNotepadsFromACollection = (notepadCollectionIdx: number) => {
    const newSelectedFlashcardsIds = [...selectedNotepadCollectionsIds]
    filteredNotepadCollections[notepadCollectionIdx].notepads.forEach(
      (notepad) => {
        if (!selectedNotepadCollectionsIds.includes(notepad.id!)) {
          newSelectedFlashcardsIds.push(notepad.id!)
        }
      },
    )
    setSelectedNotepadCollectionsIds(newSelectedFlashcardsIds)
  }

  const onSelectAllNotepads = () => {
    if (selectedNotepadCollectionsIds.length === totalNumberOfNotepads) {
      setSelectedNotepadCollectionsIds([])
      setSelectedAll(false)
    } else {
      const newSelectedFlashcardsIds = [...selectedNotepadCollectionsIds]
      notepadCollections.forEach((notepadCollection) => {
        notepadCollection.notepads.forEach((notepad) => {
          if (!selectedNotepadCollectionsIds.includes(notepad.id!)) {
            newSelectedFlashcardsIds.push(notepad.id!)
          }
        })
      })
      setSelectedNotepadCollectionsIds(newSelectedFlashcardsIds)
      setSelectedAll(true)
    }
  }

  const filterNotepads = (notepads: Notepad[], query: string) => {
    return notepads.filter((notepad) => {
      const title = notepad.title
      return title?.toLowerCase().includes(query.toLowerCase())
    })
  }

  const handleFilterNotepadsbyQuery = (notepads: Notepad[], query: string) => {
    if (!query) {
      return notepads
    }

    const filteredNotepads = filterNotepads(notepads, query)
    return filteredNotepads
  }

  const deleteNotepadCollection = () => {
    const newNotepadCollections = notepadCollections.filter(
      (_, index) => index !== notepadCollectionIdxSelected,
    )
    setNotepadCollections(newNotepadCollections)
    setShowDeleteModal(false)
    setNotepadCollectionIdxSelected(undefined)
  }

  const deleteNotepad = () => {
    const newNotepadCollections = notepadCollections.map(
      (collection, index) => {
        if (index === notepadCollectionIdxSelected) {
          collection.notepads = collection.notepads.filter(
            (notepad) => notepad.id !== notepadSelected?.id,
          )
        }
      },
    )
    setShowDeleteNotepadModal(false)
    setNotepadIdSelected(undefined)
  }

  const onAddNewNotepad = (
    notepadCollectionIdx?: number,
    notepadId?: string,
  ) => {
    setNotepadCollectionIdxSelected(notepadCollectionIdx ?? undefined)
    setNotepadIdSelected(notepadId ?? undefined)

    setShowEditNotepadModal(true)
  }

  const checkIfCollectionNameAlredyInUse = (
    newName: string,
    notepadCollection?: Collection,
  ) => {
    let notepadCollectionNameAlreadyInUse = false

    // check if the collection name is already in use and it's not the same collection
    if (!notepadCollection) {
      notepadCollectionNameAlreadyInUse = notepadCollections.some(
        (item) => item.title === newName,
      )
    } else {
      notepadCollectionNameAlreadyInUse = notepadCollections.some(
        (item) => item.title === newName && item.id !== notepadCollection.id,
      )
    }

    return notepadCollectionNameAlreadyInUse
  }

  const checkIfNotepadNameAlredyInUse = (
    newName: string,
    notepadCollection: Collection,
    notepad?: CollectionNotepadsItem,
  ) => {
    let notepadNameAlreadyInUse = false

    // check if the notepad name is already in use and it's not the same notepad
    if (!notepad) {
      notepadNameAlreadyInUse =
        notepadCollection?.notepads.some((item) => item.title === newName) ??
        false
    } else {
      notepadNameAlreadyInUse =
        notepadCollection?.notepads.some(
          (item) => item.title === newName && item.id !== notepad.id,
        ) ?? false
    }

    return notepadNameAlreadyInUse
  }

  const onNotepadCollectionChanged = () => {
    setShowEditNotepadCollectionModal(false)
    refetchUserNotepadCollections()
  }

  const onNotepadChanged = () => {
    setShowEditNotepadModal(false)
    refetchUserNotepadCollections()
  }

  const onSubmitNotepadCollectionEdit: SubmitHandler<
    CollectionFormValues
  > = async () => {
    const color = notepadCollectionColor ?? defaultNotepadCollectionColor

    let name = defaultNotepadCollectionName

    if (notepadCollectionName) {
      name = notepadCollectionName
    }

    const notepadCollectionNameAlreadyInUse = checkIfCollectionNameAlredyInUse(
      name,
      currentNotepadCollection,
    )

    if (notepadCollectionNameAlreadyInUse) {
      setDuplicatedNotepadCollectionName(true)
      return
    }

    setDuplicatedNotepadCollectionName(false)

    // If it's editing an existing notepads
    if (currentNotepadCollection) {
      updateNotepadCollectionAPI({
        collectionId: currentNotepadCollection!.id!,
        data: { ...currentNotepadCollection, title: name, color: color },
      })
    }
    // If it's creating a new notepads
    else {
      const newNotepadCollection: Collection = {
        title: name,
        color: color,
        notepads: [],
      }

      createNotepadCollectionAPI({ data: newNotepadCollection })
    }

    setNotepadCollectionName(undefined)
    setNotepadCollectionColor(defaultNotepadCollectionColor)
  }

  const onSubmitNotepadEdit: SubmitHandler<NotepadFormValues> = async () => {
    let notepadCollectionIdx =
      notepadCollectionIdxSelected === undefined
        ? initialNotepadCollectionIdx
        : notepadCollectionIdxSelected

    if (newNotepadCollectionIdx !== undefined) {
      notepadCollectionIdx = newNotepadCollectionIdx
    }

    const newNotepadCollection = notepadCollections.find(
      (_, idx) => idx === notepadCollectionIdx,
    )

    let name = defaultNotepadName

    if (notepadName) {
      name = notepadName
    }

    const notepadNameAlreadyInUse = checkIfNotepadNameAlredyInUse(
      name,
      newNotepadCollection!,
      notepadSelected,
    )

    if (notepadNameAlreadyInUse) {
      setDuplicatedNotepadName(true)
      return
    }

    setDuplicatedNotepadName(false)

    // If it's editing an existing notepads
    if (notepadSelected) {
      updateNotepadAPI({
        notepadId: notepadSelected.id!,
        data: {
          ...notepadSelected,
          title: name,
          collection: newNotepadCollection!.id!,
        },
      })
    }
    // If it's creating a new notepads
    else {
      createNotepadAPI({
        data: {
          title: name,
          collection: newNotepadCollection!.id!,
        },
      })
    }

    setNotepadName(undefined)
    setNotepadCollectionColor(defaultNotepadCollectionColor)
    setNotepadCollectionIdxSelected(undefined)
    setNewNotepadCollectionIdx(undefined)
  }

  const handleExport = (collection: Collection) => {
    let json
    try {
      json = exportNotepadCollection(collection)
    } catch (e) {
      alert(t('flashcards.errors.exportCollectionError'))
      console.error(e)
      return
    }

    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')

    // Replace any special characters in the collection title with underscores and make it lowercase
    // Append the timestamp to the filename to prevent overwriting existing files

    let newName = collection.title!.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    newName = newName + '_' + new Date().getTime()

    a.href = url
    a.download = newName
    a.click()

    URL.revokeObjectURL(url)
  }

  const handleImport = (file?: File) => {
    if (file) {
      let importedCollection: Collection | null = null
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          try {
            importedCollection = importNotepadCollection(
              reader.result as string,
            )
            if (importedCollection) {
              importNotepadCollectionAPI({
                data: importedCollection as CollectionImport,
              })
            } else {
              alert(t('notepad.errors.importCollectionError'))
            }
          } catch (e) {
            alert(t('notepad.errors.importCollectionError'))
            console.error(e)
            return
          }
        }
      }
      reader.readAsText(file)
    }
  }

  const handleTemplateImport = (file?: File) => {
    if (file) {
      let importedCollection: Collection | null = null
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          try {
            importedCollection = importNotepadCollection(
              reader.result as string,
            )
            if (importedCollection) {
              // async function because this function takes a while to execute
              importTemplateNotepadCollectionAPI({
                data: importedCollection as CollectionImport,
              })
              alert(t('notepad.importTemplateBegin'))
            } else {
              alert(t('notepad.errors.importCollectionError'))
            }
          } catch (e) {
            alert(t('notepad.errors.importCollectionError'))
            console.error(e)
            return
          }
        }
      }
      reader.readAsText(file)
    }
  }

  const onImportNotepadCollectionClicked = (file?: File) => {
    handleImport(file)
  }

  const onImportTemplateNotepadCollectionClicked = (file?: File) => {
    handleTemplateImport(file)
  }

  const onExportNotepadCollectionClicked = (collection: Collection) => {
    handleExport(collection)
  }

  const {
    isLoading: notepadCollectionsIsLoading,
    refetch: refetchUserNotepadCollections,
  } = useCollectionsList({
    query: {
      onSuccess: (data) => {
        setNotepadCollections(data)
      },
      onError: (error) => {
        console.error('error', error)
      },
    },
  })

  const { mutate: createNotepadCollectionAPI } = useCollectionCreate({
    mutation: {
      onSuccess: (data) => {
        notepadCollections.push(data)
        onNotepadCollectionChanged()
      },
    },
  })

  const { mutate: importNotepadCollectionAPI } = useNotepadCollectionImport({
    mutation: {
      onSuccess: (data) => {
        notepadCollections.push(data)
        onNotepadCollectionChanged()
        alert(t('notepad.importSuccess', { collection: data.title }))
      },
      onError: () => {
        alert(t('notepad.errors.importCollectionError'))
      },
    },
  })

  const { mutateAsync: importTemplateNotepadCollectionAPI } =
    useNotepadCollectionTemplateImport({
      mutation: {
        onSuccess: (data) => {
          notepadCollections.push(data)
          onNotepadCollectionChanged()
          alert(t('notepad.importTemplateSuccess'))
        },
      },
    })

  const { mutate: updateNotepadCollectionAPI } = useCollectionUpdate({
    mutation: {
      onSuccess: (data) => {
        currentNotepadCollection!.title = data.title
        onNotepadCollectionChanged()
      },
    },
  })

  const { mutate: deleteNotepadCollectionAPI } = useDeleteCollection({
    mutation: {
      onSuccess: () => {
        deleteNotepadCollection()
      },
    },
  })

  const { mutate: createNotepadAPI } = useNotepadCreate({
    mutation: {
      onSuccess: (data) => {
        const notepadCollection = notepadCollections.find(
          (notepadCollection) => notepadCollection.id === data.collection,
        )

        if (notepadCollection) {
          notepadCollection.notepads.push(data)
        }

        onNotepadChanged()
      },
    },
  })

  const { mutate: updateNotepadAPI } = useNotepadUpdate({
    mutation: {
      onSuccess: (data) => {
        const notepadCollection = notepadCollections.find(
          (notepadCollection) => notepadCollection.id === data.collection,
        )

        if (notepadCollection) {
          const notepad = notepadCollection.notepads.find(
            (notepad) => notepad.id === data.id,
          )

          if (notepad) {
            notepad.title = data.title
          }
        }

        onNotepadChanged()
      },
    },
  })

  const { mutate: deleteNotepadAPI } = useDeleteNotepad({
    mutation: {
      onSuccess: () => {
        deleteNotepad()
      },
    },
  })

  const isLoading = useMemo(() => {
    return notepadCollectionsIsLoading
  }, [notepadCollectionsIsLoading])

  const filteredNotepadCollections = useMemo(() => {
    let filteredNotepadCollections = notepadCollections

    if (searchQuery) {
      filteredNotepadCollections = notepadCollections.map(
        (notepadCollection) => {
          let filteredNotepads = handleFilterNotepadsbyQuery(
            notepadCollection.notepads,
            searchQuery,
          )

          return { ...notepadCollection, notepads: filteredNotepads }
        },
      )

      filteredNotepadCollections = filteredNotepadCollections.filter(
        (notepadCollection) => notepadCollection.notepads.length > 0,
      )
    }

    return filteredNotepadCollections
  }, [notepadCollections, searchQuery, selectFavorites, checkedTags])

  const currentNotepadCollection = useMemo(() => {
    if (notepadCollectionIdxSelected === undefined) {
      return undefined
    }
    return filteredNotepadCollections[notepadCollectionIdxSelected]
  }, [filteredNotepadCollections, notepadCollectionIdxSelected])

  const notepadSelected = useMemo(() => {
    return currentNotepadCollection?.notepads.find(
      (notepad) => notepad.id === notepadIdSelected,
    )
  }, [currentNotepadCollection, notepadIdSelected])

  const totalNumberOfNotepads = useMemo(() => {
    let total = 0
    notepadCollections.map((notepadCollection) => {
      total += notepadCollection.notepads.length
    })
    return total
  }, [notepadCollections])

  useEffect(() => {
    setNotepadCollectionName(currentNotepadCollection?.title)
    setNotepadCollectionColor(
      currentNotepadCollection?.color ?? defaultNotepadCollectionColor,
    )
  }, [currentNotepadCollection])

  useEffect(() => {
    setNotepadName(notepadSelected?.title)
  }, [notepadSelected])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      {!showNotepadBoard ? (
        <>
          <Row className="mb-4">
            <Col className="d-flex align-items-center col-auto">
              <SearchBar
                placeholderText={t('notepad.searchNotepads')}
                className={clsx(classes.searchBar, 'me-2')}
                onSearch={setSearchQuery}
                searchQuery={searchQuery}
              />
            </Col>
            {addMode && (
              <Col>
                <OverlayTrigger
                  key="add-new-collection-tooltip"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-view-notepad`}>
                      {t('notepad.addNewCollection')}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="none"
                    onClick={() => createNewNotepadCollection()}
                    data-testid="add-new-collection-button"
                  >
                    <FolderPlus size={18} />
                  </Button>
                </OverlayTrigger>
                {notepadCollections.length > 0 && (
                  <OverlayTrigger
                    key="add-new-notepad-tooltip"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-view-notepad`}>
                        {t('notepad.addNewNotepad')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="none"
                      onClick={() => onAddNewNotepad()}
                      data-testid="create-new-notepad"
                    >
                      <FileEarmarkPlus size={18} />
                    </Button>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  key="import-new-collection-tooltip"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-view-collection`}>
                      {t('notepad.import')}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="none"
                    onClick={() =>
                      fileImportCollectionInputRef.current?.click()
                    }
                  >
                    <Upload size={18} />
                  </Button>
                </OverlayTrigger>
                <input
                  ref={fileImportCollectionInputRef}
                  type="file"
                  multiple={false}
                  accept=".json"
                  onChange={(e) => {
                    onImportNotepadCollectionClicked(e.target.files?.[0])
                  }}
                  hidden
                />
                {userIsAdmin && (
                  <>
                    <OverlayTrigger
                      key="import-new-template-collection-tooltip"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-view-collection`}>
                          {t('notepad.importTemplate')}
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="none"
                        onClick={() =>
                          fileImportTemplateCollectionInputRef.current?.click()
                        }
                      >
                        <CloudArrowUp size={18} />
                      </Button>
                    </OverlayTrigger>
                    <input
                      ref={fileImportTemplateCollectionInputRef}
                      type="file"
                      multiple={false}
                      accept=".json"
                      onChange={(e) => {
                        onImportTemplateNotepadCollectionClicked(
                          e.target.files?.[0],
                        )
                      }}
                      hidden
                    />
                  </>
                )}
              </Col>
            )}
          </Row>
          <div className={clsx(classes.notepadCollectionsBoard)}>
            {isLoading ? (
              <Loading />
            ) : filteredNotepadCollections &&
              filteredNotepadCollections.length > 0 ? (
              filteredNotepadCollections.map(
                (notepadCollection, notepadCollectionIdx) =>
                  !(!addMode && notepadCollection.notepads.length === 0) && (
                    <>
                      <div className="d-flex align-items-left mb-3">
                        <NotepadCollectionTitleDisplay
                          notepadCollection={notepadCollection}
                          notepadCollectionIdx={notepadCollectionIdx}
                          editMode={editMode}
                          onEditNotepadCollectionClicked={
                            onEditNotepadCollectionClicked
                          }
                          onExportNotepadCollectionClicked={
                            onExportNotepadCollectionClicked
                          }
                          onDeleteNotepadCollectionClicked={
                            onDeleteNotepadCollectionClicked
                          }
                          collection_id={notepadCollection.id}
                        />
                        {selectingMode && (
                          <Button
                            variant="link"
                            onClick={() =>
                              onSelectAllNotepadsFromACollection(
                                notepadCollectionIdx,
                              )
                            }
                          >
                            {t('common.selectAll')}
                          </Button>
                        )}
                      </div>
                      <Row
                        className={clsx(classes.notepadCollectionsContainer)}
                      >
                        {notepadCollection.notepads.map((notepad) => (
                          <Col
                            className={clsx(
                              classes.notepadCollectionsItem,
                              'col-2',
                            )}
                          >
                            <Card
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                              className={clsx(
                                selectingMode && isNotepadSelected(notepad.id!)
                                  ? 'border border-3 border-success'
                                  : null,
                              )}
                            >
                              <Card.Body
                                className={classes.notepadCollectionsItemBody}
                              >
                                <div
                                  className={clsx(
                                    classes.notepad,
                                    'd-flex justify-content-center align-items-center',
                                  )}
                                >
                                  <Typography variant="span">
                                    {notepad.title}
                                  </Typography>

                                  {!showEditNotepadModal && (
                                    <div
                                      className={clsx(
                                        classes.notepadHover,
                                        'justify-content-center align-items-center',
                                      )}
                                    >
                                      <OverlayTrigger
                                        key="view-notepad-top"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-view-notepad`}>
                                            {t('notepad.viewNotepad')}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="none"
                                          className={clsx(
                                            classes.notepadHoverButton,
                                            'd-flex justify-content-center align-items-center',
                                            'me-2',
                                          )}
                                          onClick={() => {
                                            setShowNotepadBoard(true)
                                            setNotepadCollectionIdxSelected(
                                              notepadCollectionIdx ?? undefined,
                                            )
                                            setNotepadIdSelected(
                                              notepad.id ?? undefined,
                                            )
                                          }}
                                        >
                                          <Eye />
                                        </Button>
                                      </OverlayTrigger>
                                      {editMode && (
                                        <>
                                          <OverlayTrigger
                                            key="edit-notepad-top"
                                            placement="top"
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-edit-notepad`}
                                              >
                                                {t('notepad.editNotepad')}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="none"
                                              className={clsx(
                                                classes.notepadHoverButton,
                                                'd-flex justify-content-center align-items-center me-2',
                                              )}
                                              onClick={() =>
                                                onAddNewNotepad(
                                                  notepadCollectionIdx,
                                                  notepad.id,
                                                )
                                              }
                                            >
                                              <Pencil />
                                            </Button>
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            key="delete-notepad-top"
                                            placement="top"
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-delete-notepad`}
                                              >
                                                {t('notepad.deleteNotepad')}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="none"
                                              className={clsx(
                                                classes.notepadHoverButton,
                                                'd-flex justify-content-center align-items-center',
                                              )}
                                              onClick={() =>
                                                onDeleteNotepadClicked(
                                                  notepadCollectionIdx,
                                                  notepad.id!,
                                                )
                                              }
                                            >
                                              <Trash />
                                            </Button>
                                          </OverlayTrigger>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                        {!searchQuery && addMode && (
                          <Col
                            className={clsx(
                              classes.notepadCollectionsItem,
                              'col-2',
                            )}
                          >
                            <Card
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                              className={clsx(
                                'bg-light',
                                'border-white',
                                'shadow-none',
                              )}
                              onClick={() =>
                                onAddNewNotepad(notepadCollectionIdx)
                              }
                            >
                              <Card.Body>
                                <div
                                  className={clsx(
                                    classes.notepad,
                                    'd-flex align-items-center justify-content-center',
                                  )}
                                >
                                  <div>
                                    <Typography>
                                      {t('notepad.addNewNotepad')}
                                    </Typography>
                                    <Typography variant="h5">+</Typography>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        )}
                      </Row>
                    </>
                  ),
              )
            ) : (
              <>
                <Row
                  className={clsx(
                    classes.notepadsBoard,
                    'd-flex justify-content-center align-items-center',
                  )}
                >
                  <Typography variant="h5">
                    {t('notepad.errors.noCollections')}
                  </Typography>
                </Row>
              </>
            )}
          </div>
          <Modal
            show={showEditNotepadCollectionModal}
            onHide={onCancelCreateNotepadCollection}
            centered
          >
            <Modal.Header>
              <Modal.Title>
                {notepadCollectionIdxSelected !== undefined
                  ? t('notepad.editNotepadCollection') +
                    ` \"${currentNotepadCollection?.title}"`
                  : t('notepad.createNotepadCollection')}
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmitNotepadCollectionEdit)}>
              <Modal.Body>
                <Form.Group controlId="notepad-collection-name">
                  <Form.Label>{t('common.name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={defaultNotepadCollectionName}
                    value={notepadCollectionName}
                    onChange={(e) => {
                      setNotepadCollectionName(e.target.value)
                    }}
                  />
                  {duplicatedNotepadCollectionName && (
                    <Form.Text className="text-danger">
                      {t('notepad.errors.duplicatedNotepadCollectionName')}
                    </Form.Text>
                  )}
                </Form.Group>
                <div className="d-flex align-items-center mt-4 mb-2">
                  <div>{t('common.color')}</div>
                  <Button
                    variant="white"
                    className={clsx(
                      classes.notepadCollectionColorPickerContainer,
                    )}
                    onClick={() => setShowColorPicker(!showColorPicker)}
                  >
                    <div
                      className={clsx(classes.circle)}
                      style={{
                        backgroundColor: notepadCollectionColor,
                        width: 15,
                        height: 15,
                        border: '0.5px solid rgb(33, 37, 41)',
                      }}
                    ></div>
                  </Button>
                </div>
                {showColorPicker && (
                  <ColorPicker
                    colorPickerComponent={
                      <CirclePicker
                        show={showColorPicker}
                        className={clsx(classes.colorPicker)}
                        color={notepadCollectionColor}
                        colors={[
                          material.red['500'],
                          material.pink['500'],
                          material.purple['500'],
                          material.lightBlue['500'],
                          material.teal['500'],
                          material.lightGreen['500'],
                          material.yellow['500'],
                          material.orange['500'],
                        ]}
                        // @ts-ignore
                        onChangeComplete={(color: {
                          rgb: { r: number; g: number; b: number; a: number }
                        }) => {
                          const rgbColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
                          setNotepadCollectionColor(rgbColor)
                        }}
                      />
                    }
                    show={showColorPicker}
                    onClose={() => setShowColorPicker(false)}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="dark"
                  onClick={() => onCancelCreateNotepadCollection()}
                >
                  {t('common.cancel')}
                </Button>
                <Button variant="success" type="submit">
                  {t('common.save')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={showEditNotepadModal}
            onHide={onCancelCreateNotepad}
            centered
          >
            <Modal.Header>
              <Modal.Title>
                {notepadSelected !== undefined
                  ? t('notepad.editNotepad') + ` \"${notepadSelected?.title}"`
                  : t('notepad.createNotepad')}
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmitNotepadEdit)}>
              <Modal.Body>
                <Form.Group controlId="notepad-collection">
                  <Form.Label>{t('notepad.collection')}</Form.Label>
                  <Form.Select
                    name="notepadCollectionIdx"
                    defaultValue={
                      notepadCollectionIdxSelected === undefined
                        ? initialNotepadCollectionIdx
                        : notepadCollectionIdxSelected
                    }
                    onChange={(e) => {
                      const newIndex = Number.parseInt(e.target.value)
                      setNewNotepadCollectionIdx(newIndex)
                    }}
                  >
                    {notepadCollections.map((collection, idx) => (
                      <option key={collection.title} value={idx}>
                        {collection.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="notepad-name">
                  <Form.Label>{t('common.name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={defaultNotepadName}
                    value={notepadName ?? notepadSelected?.title}
                    onChange={(e) => {
                      setNotepadName(e.target.value)
                    }}
                  />
                  {duplicatedNotepadName && (
                    <Form.Text className="text-danger">
                      {t('notepad.errors.duplicatedNotepadName')}
                    </Form.Text>
                  )}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={() => onCancelCreateNotepad()}>
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  data-testid={`edit-notepad-${notepadSelected?.id}`}
                >
                  {t('common.save')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>
                {t('notepad.deleteNotepadCollection')} "
                {currentNotepadCollection?.title}"
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t('notepad.deleteNotepadCollectionDescription')}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => {
                  setShowDeleteModal(false)
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  deleteNotepadCollectionAPI({
                    collectionId: currentNotepadCollection!.id!,
                  })
                }
              >
                {t('common.delete')}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showDeleteNotepadModal}
            onHide={() => setShowDeleteNotepadModal(false)}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>
                {t('notepad.deleteNotepad')} "{notepadSelected?.title}"
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('notepad.deleteNotepadDescription')}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => {
                  setShowDeleteNotepadModal(false)
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  deleteNotepadAPI({ notepadId: notepadSelected!.id! })
                }
              >
                {t('common.delete')}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <Row className="mb-3 mt-1">
            <Col>
              <span
                onClick={() => {
                  refetchUserNotepadCollections()
                  setShowNotepadBoard(false)
                }}
                role={'button'}
                className="d-flex align-items-center"
              >
                <ArrowLeft className="mb-1 me-1" />
                <Typography variant="span" fontWeight="light">
                  {t('common.goBack')}
                </Typography>
              </span>
            </Col>
          </Row>
          <NotepadBoard notepad={notepadSelected!} />
        </>
      )}
    </>
  )
}

export default NotepadCollectionsBoard
