import { useMutation, useQuery} from 'react-query';
import { instance } from '../instance';

export const shareNotepad = (collection_id: string, data: any) => {
    return instance(
          {url: `/notepad/collections/${collection_id}/share/`, method: 'POST',
          headers: {'Content-Type': 'application/json', },
          data
        },);
}

export const useShareNotepad = (collection_id: string) => {
    return useMutation({mutationFn: (data: any) => shareNotepad(collection_id, data)});
}
