import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Modal } from 'react-bootstrap'
import { Download, Pencil, Share, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Collection } from '../../api/model'
import { Typography } from '../../atoms'
import { useShareNotepad } from '../../api/manual/useNotepad'
import { useUserInfo } from '../../api/user/user'
import WarningModalComponent from '../../atoms/WarningModal/WarningModalComponent'
import classes from './NotepadCollectionTitleDisplay.module.css'



interface NotepadCollectionTitleDisplayProps {
  notepadCollectionIdx: number
  notepadCollection: Collection
  editMode?: boolean
  onEditNotepadCollectionClicked: (notepadCollectionIdx: number) => void
  onExportNotepadCollectionClicked: (notepadCollection: Collection) => void
  onDeleteNotepadCollectionClicked: (notepadCollectionIdx: number) => void
  collection_id?: string
}

const NotepadCollectionTitleDisplay: FC<NotepadCollectionTitleDisplayProps> = ({
  notepadCollectionIdx,
  notepadCollection,
  editMode = true,
  onEditNotepadCollectionClicked,
  onExportNotepadCollectionClicked,
  onDeleteNotepadCollectionClicked,
  collection_id
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const shareNotepad = useShareNotepad(collection_id);
  const { data: userInfo } = useUserInfo();
  const [shareModal, setShareModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const handleShareNotepad = () => {
    const payload = {"shareable": true};
    shareNotepad.mutate(payload);
  };

  useEffect(() => {
    if (shareNotepad.isSuccess) {
      setShareModal(true);
      setWarningModal(false);
      setTimeout(() => {
        setShareModal(false)
        navigate(0);
      }, 2000);

    }
    }, [shareNotepad.data]);

  return (
    <>
    <div className="d-flex align-items-center">
      <div
        className={clsx(classes.circle, 'mr-1')}
        style={{
          backgroundColor: notepadCollection.color,
          width: 20,
          height: 20,
          marginRight: 10,
          padding: 5,
          border: '0.5px solid rgb(33, 37, 41)',
        }}
      />
      <Typography className={classes.notepadCollectionName} variant="h5">
        {notepadCollection.title}
      </Typography>
      {editMode && (
        <Dropdown>
          <Dropdown.Toggle
            variant="default"
            className={classes.notepadCollectionMenuDropdown}
          />
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={() => onEditNotepadCollectionClicked(notepadCollectionIdx)}
            >
              <div className="d-flex align-items-center">
                <Pencil className={classes.icon} />
                {t('common.edit')}
              </div>
            </Dropdown.Item>
            {userInfo?.is_admin && (
              <Dropdown.Item
              eventKey="1"
              onClick={() => setWarningModal(!warningModal)}
            >
              <div className="d-flex align-items-center">
                <Share className={classes.icon} />
                Partilhar
              </div>
            </Dropdown.Item>
            )}

            <Dropdown.Item
              eventKey="2"
              onClick={() => onExportNotepadCollectionClicked(notepadCollection)}
            >
              <div className="d-flex align-items-center">
                <Download className={classes.icon} />
                {t('common.export')}
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              onClick={() => onDeleteNotepadCollectionClicked(notepadCollectionIdx)}
            >
              <div className="d-flex align-items-center">
                <Trash className={classes.icon} />
                {t('common.delete')}
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
    <Modal show={shareModal} onHide={() => setShareModal(false)}>
      <Modal.Body>
        <Typography variant="h5">Notepad Partilhada com sucesso</Typography>
      </Modal.Body>
    </Modal>
    <WarningModalComponent show={warningModal} onClose={() => setWarningModal(false)} onShare={handleShareNotepad}>
      <Typography variant="h5">Tem certeza que deseja partilhar este Notepad?</Typography>
      <p>Esta ação é irreversivel</p>
    </WarningModalComponent>
    </>
  )
}

export default NotepadCollectionTitleDisplay
